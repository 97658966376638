exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-en-about-tsx": () => import("./../../../src/pages/en/about.tsx" /* webpackChunkName: "component---src-pages-en-about-tsx" */),
  "component---src-pages-en-blog-en-tsx": () => import("./../../../src/pages/en/blog-en.tsx" /* webpackChunkName: "component---src-pages-en-blog-en-tsx" */),
  "component---src-pages-en-charity-work-tsx": () => import("./../../../src/pages/en/charity-work.tsx" /* webpackChunkName: "component---src-pages-en-charity-work-tsx" */),
  "component---src-pages-en-contact-tsx": () => import("./../../../src/pages/en/contact.tsx" /* webpackChunkName: "component---src-pages-en-contact-tsx" */),
  "component---src-pages-en-environmental-protection-tsx": () => import("./../../../src/pages/en/environmental-protection.tsx" /* webpackChunkName: "component---src-pages-en-environmental-protection-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-en-login-tsx": () => import("./../../../src/pages/en/login.tsx" /* webpackChunkName: "component---src-pages-en-login-tsx" */),
  "component---src-pages-en-news-tsx": () => import("./../../../src/pages/en/news.tsx" /* webpackChunkName: "component---src-pages-en-news-tsx" */),
  "component---src-pages-en-programs-tsx": () => import("./../../../src/pages/en/programs.tsx" /* webpackChunkName: "component---src-pages-en-programs-tsx" */),
  "component---src-pages-en-reports-tsx": () => import("./../../../src/pages/en/reports.tsx" /* webpackChunkName: "component---src-pages-en-reports-tsx" */),
  "component---src-pages-en-services-tsx": () => import("./../../../src/pages/en/services.tsx" /* webpackChunkName: "component---src-pages-en-services-tsx" */),
  "component---src-pages-en-support-ask-tsx": () => import("./../../../src/pages/en/support-ask.tsx" /* webpackChunkName: "component---src-pages-en-support-ask-tsx" */),
  "component---src-pages-en-youth-activism-tsx": () => import("./../../../src/pages/en/youth-activism.tsx" /* webpackChunkName: "component---src-pages-en-youth-activism-tsx" */),
  "component---src-pages-en-youth-employment-tsx": () => import("./../../../src/pages/en/youth-employment.tsx" /* webpackChunkName: "component---src-pages-en-youth-employment-tsx" */),
  "component---src-pages-humanitarni-rad-tsx": () => import("./../../../src/pages/humanitarni-rad.tsx" /* webpackChunkName: "component---src-pages-humanitarni-rad-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-izvestaji-tsx": () => import("./../../../src/pages/izvestaji.tsx" /* webpackChunkName: "component---src-pages-izvestaji-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-o-nama-tsx": () => import("./../../../src/pages/o-nama.tsx" /* webpackChunkName: "component---src-pages-o-nama-tsx" */),
  "component---src-pages-omladinski-aktivizam-tsx": () => import("./../../../src/pages/omladinski-aktivizam.tsx" /* webpackChunkName: "component---src-pages-omladinski-aktivizam-tsx" */),
  "component---src-pages-podrzi-ask-tsx": () => import("./../../../src/pages/podrzi-ask.tsx" /* webpackChunkName: "component---src-pages-podrzi-ask-tsx" */),
  "component---src-pages-prijavite-se-tsx": () => import("./../../../src/pages/prijavite-se.tsx" /* webpackChunkName: "component---src-pages-prijavite-se-tsx" */),
  "component---src-pages-programi-tsx": () => import("./../../../src/pages/programi.tsx" /* webpackChunkName: "component---src-pages-programi-tsx" */),
  "component---src-pages-usluge-tsx": () => import("./../../../src/pages/usluge.tsx" /* webpackChunkName: "component---src-pages-usluge-tsx" */),
  "component---src-pages-vesti-tsx": () => import("./../../../src/pages/vesti.tsx" /* webpackChunkName: "component---src-pages-vesti-tsx" */),
  "component---src-pages-zaposljavanje-mladih-tsx": () => import("./../../../src/pages/zaposljavanje-mladih.tsx" /* webpackChunkName: "component---src-pages-zaposljavanje-mladih-tsx" */),
  "component---src-pages-zastita-zivotne-sredine-tsx": () => import("./../../../src/pages/zastita-zivotne-sredine.tsx" /* webpackChunkName: "component---src-pages-zastita-zivotne-sredine-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

